import { Component, DestroyRef, inject, OnInit } from "@angular/core";
import {
  ActivatedRoute,
  NavigationEnd,
  Router,
  RouterOutlet,
} from "@angular/router";
import { ToastModule } from "primeng/toast";
import { AnalyticsService } from "@platform-app/app/core/analytics/analytics.service";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { RoutingUtility } from "@platform-app/app/main/shared/utilities/routing.utility";
import { PageTrackingConfig } from "@platform-app/app/core/analytics/models";

@Component({
  selector: "app-root",
  standalone: true,
  imports: [RouterOutlet, ToastModule],
  templateUrl: "./app.component.html",
})
export class AppComponent implements OnInit {
  private readonly destroyRef = inject(DestroyRef);
  private readonly router = inject(Router);
  private readonly activatedRoute = inject(ActivatedRoute);
  private readonly analyticsService = inject(AnalyticsService);

  ngOnInit() {
    this.subscribeForPageTracking();
  }

  private subscribeForPageTracking() {
    this.router.events
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((event) => {
        if (event instanceof NavigationEnd) {
          const route = RoutingUtility.getDeepestRoute(this.activatedRoute);

          const trackingConfig = route?.snapshot.data[
            "tracking"
          ] as PageTrackingConfig;

          if (!trackingConfig) return;

          const { page, trackQueryParams } = trackingConfig;

          let params: Record<string, unknown> = {};
          if (trackQueryParams) {
            params =
              trackQueryParams instanceof Array
                ? Object.fromEntries(
                    Object.entries(route.snapshot.params).filter(([k]) =>
                      trackQueryParams.includes(k),
                    ),
                  )
                : route.snapshot.params;
          }

          if (page)
            this.analyticsService.trackPageView({
              page,
              ...params,
            });
        }
      });
  }
}
