import { Injectable } from "@angular/core";
import { environment } from "@platform-app/environments/environment";

@Injectable({
  providedIn: "root",
})
export class LoadScriptService {
  loadGoogleMapsScripts(): void {
    this.loadGoogleMapsAPI();
    this.loadMarkerClusterer();
  }

  loadGoogleMapsAPI(): void {
    const googleMapsScript = document.createElement("script");
    googleMapsScript.src = `https://maps.googleapis.com/maps/api/js?v=quarterly&key=${environment.googleApiKey}&loading=async&libraries=places`;
    document.body.appendChild(googleMapsScript);
  }

  loadMarkerClusterer(): void {
    const markerClustererScript = document.createElement("script");
    markerClustererScript.src =
      "https://unpkg.com/@googlemaps/markerclusterer/dist/index.min.js";
    document.body.appendChild(markerClustererScript);
  }
}
