/* tslint:disable */
/* eslint-disable */
import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiConfiguration, ApiConfigurationParams } from './api-configuration';

import { AccountService } from './services/account.service';
import { AffiliationAgreementsService } from './services/affiliation-agreements.service';
import { ApolloService } from './services/apollo.service';
import { ApplicationsService } from './services/applications.service';
import { ClinicService } from './services/clinic.service';
import { DisciplineService } from './services/discipline.service';
import { ExterniFeedbacksService } from './services/externi-feedbacks.service';
import { LanguageService } from './services/language.service';
import { OpportunityService } from './services/opportunity.service';
import { OrganizationService } from './services/organization.service';
import { PlacementsService } from './services/placements.service';
import { PublishedOpportunityService } from './services/published-opportunity.service';
import { SchoolService } from './services/school.service';
import { SkillsService } from './services/skills.service';
import { StudentService } from './services/student.service';
import { TeamsService } from './services/teams.service';
import { TimeZoneService } from './services/time-zone.service';
import { UserProfileService } from './services/user-profile.service';

/**
 * Module that provides all services and configuration.
 */
@NgModule({
  imports: [],
  exports: [],
  declarations: [],
  providers: [
    AccountService,
    AffiliationAgreementsService,
    ApolloService,
    ApplicationsService,
    ClinicService,
    DisciplineService,
    ExterniFeedbacksService,
    LanguageService,
    OpportunityService,
    OrganizationService,
    PlacementsService,
    PublishedOpportunityService,
    SchoolService,
    SkillsService,
    StudentService,
    TeamsService,
    TimeZoneService,
    UserProfileService,
    ApiConfiguration
  ],
})
export class ApiModule {
  static forRoot(params: ApiConfigurationParams): ModuleWithProviders<ApiModule> {
    return {
      ngModule: ApiModule,
      providers: [
        {
          provide: ApiConfiguration,
          useValue: params
        }
      ]
    }
  }

  constructor( 
    @Optional() @SkipSelf() parentModule: ApiModule,
    @Optional() http: HttpClient
  ) {
    if (parentModule) {
      throw new Error('ApiModule is already loaded. Import in your base AppModule only.');
    }
    if (!http) {
      throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
      'See also https://github.com/angular/angular/issues/20575');
    }
  }
}
