import { inject } from "@angular/core";
import { ResolveFn, Router } from "@angular/router";
import { PublishedOpportunityService } from "@platform-app/app/core/api/services";
import { catchError, EMPTY, map } from "rxjs";
import { GetPublishedOpportunitySeatsAvailabilityCalculationType } from "../core/api/models";
import { DateUtility } from "../main/shared/utilities/date.utility";

export const enrollmentResolver: ResolveFn<void | null> = (route) => {
  const router = inject(Router);
  const opportunityId = route.paramMap.get("id");
  const publishedOpportunityService = inject(PublishedOpportunityService);

  if (opportunityId) {
    return publishedOpportunityService
      .getPublishedOpportunity({
        opportunityId: opportunityId,
        body: {
          seatsCalculationType:
            GetPublishedOpportunitySeatsAvailabilityCalculationType.ProvideDates,
          studentId: null,
          seatsAvailabilityStartDate: DateUtility.convertToDateOnlyString(
            new Date(),
          ),
          seatsAvailabilityEndDate: DateUtility.convertToDateOnlyString(
            new Date(),
          ),
        },
      })
      .pipe(
        map(() => null), // Map the successful response to `null`
        catchError(() => {
          handleError(router);
          return EMPTY;
        }),
      );
  } else {
    handleError(router);
    return EMPTY;
  }
};

function handleError(router: Router) {
  router.navigate(["/dashboard"]);
}
