import { ApplicationConfig, importProvidersFrom } from "@angular/core";
import {
  InMemoryScrollingFeature,
  InMemoryScrollingOptions,
  provideRouter,
  withInMemoryScrolling,
} from "@angular/router";
import { routes } from "./app.routes";
import { provideNgxs } from "./ngxs.config";
import { provideHttpClient, withInterceptors } from "@angular/common/http";
import { ApiModule } from "./core/api/api.module";
import { environment } from "../environments/environment";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { provideAuthInitializer } from "./core/auth/initializer";
import {
  attachTokenInterceptor,
  redirectOnForbiddenInterceptor,
} from "./core/auth/interceptors";
import { provideGoogleMapsInitializer } from "./core/google-maps/initializer";
import { NgCircleProgressModule } from "ng-circle-progress";
import { MessageService } from "primeng/api";
import { showToastOnServerErrorInterceptor } from "./core/errors/interceptors";
import { provideGoogleAuth } from "./core/auth/google/google-auth";

const scrollConfig: InMemoryScrollingOptions = {
  scrollPositionRestoration: "top",
  anchorScrolling: "enabled",
};
const inMemoryScrollingFeature: InMemoryScrollingFeature =
  withInMemoryScrolling(scrollConfig);

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(routes, inMemoryScrollingFeature),
    provideHttpClient(
      withInterceptors([
        showToastOnServerErrorInterceptor,
        redirectOnForbiddenInterceptor,
        attachTokenInterceptor,
      ]),
    ),
    importProvidersFrom(ApiModule.forRoot({ rootUrl: environment.apiUrl })),
    provideNgxs(),
    provideAuthInitializer(),
    importProvidersFrom(BrowserAnimationsModule),
    importProvidersFrom(NgCircleProgressModule.forRoot()),
    provideGoogleMapsInitializer(),
    MessageService,
    provideGoogleAuth(),
  ],
};
