import {
  GoogleLoginProvider,
  SocialAuthServiceConfig,
} from "@abacritt/angularx-social-login";
import { Provider } from "@angular/core";
import { environment } from "@platform-app/environments/environment";

export const provideGoogleAuth: () => Provider = () => ({
  provide: "SocialAuthServiceConfig",
  useValue: {
    autoLogin: false,
    providers: [
      {
        id: "google",
        provider: new GoogleLoginProvider(environment.googleClientId, {
          oneTapEnabled: false,
          scopes: "email profile",
        }),
      },
    ],
  } as SocialAuthServiceConfig,
});
