import { ActivatedRoute } from "@angular/router";

export class RoutingUtility {
  static getDeepestRoute(route: ActivatedRoute): ActivatedRoute {
    let deepestRoute = route;
    while (deepestRoute?.firstChild) {
      deepestRoute = deepestRoute.firstChild;
    }
    return deepestRoute;
  }
}
