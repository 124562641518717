import { Routes } from "@angular/router";
import { authorizedOnly } from "./core/auth/guards";
import { enrollmentResolver } from "./enrollment/enrollment.resolver";
import { provideStates } from "@ngxs/store";
import { AgreementBuilderState } from "@platform-app/app/agreement-builder/agreement-builder.state";
import { Permissions } from "@platform-app/app/core/api/models/permissions";

export const routes: Routes = [
  {
    path: "account",
    loadComponent: () =>
      import("./account/account-layout.component").then(
        (mod) => mod.AccountLayoutComponent,
      ),
    loadChildren: () =>
      import("./account/account.routes").then((mod) => mod.AccountRoutes),
  },
  {
    path: "enrollment/:id",
    resolve: { data: enrollmentResolver },
    canActivate: [
      authorizedOnly([
        Permissions.SchoolApplicationsView,
        Permissions.ClinicApplicationsView,
      ]),
    ],
    loadComponent: () =>
      import("./enrollment/enrollment-layout.component").then(
        (c) => c.EnrollmentLayoutComponent,
      ),
    loadChildren: () =>
      import("./enrollment/enrollment.routes").then((m) => m.EnrollmentRoutes),
  },
  {
    path: "agreement-builder",
    providers: [provideStates([AgreementBuilderState])],
    loadComponent: () =>
      import("./agreement-builder/agreement-builder-layout.component").then(
        (c) => c.AgreementBuilderLayoutComponent,
      ),
  },
  {
    path: "",
    loadComponent: () =>
      import("./main/main-layout.component").then(
        (mod) => mod.MainLayoutComponent,
      ),
    loadChildren: () =>
      import("./main/main.routes").then((mod) => mod.MainRoutes),
    canActivate: [authorizedOnly()],
  },
  {
    path: "**",
    redirectTo: "",
  },
];
