import { APP_INITIALIZER, Provider } from "@angular/core";
import { LoadScriptService } from "./load-script.service";
import { environment } from "@platform-app/environments/environment";

export const initializeGoogleMaps = (loadScriptService: LoadScriptService) => {
  return () => {
    if (environment.googleApiKey) loadScriptService.loadGoogleMapsScripts();
  };
};

export const provideGoogleMapsInitializer: () => Provider = () => ({
  provide: APP_INITIALIZER,
  useFactory: initializeGoogleMaps,
  multi: true,
  deps: [LoadScriptService],
});
